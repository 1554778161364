import { graphql, Link } from "gatsby";
import React from "react";
import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import { H1, H3 } from "../components/common/Typography";
import PersonCard from "../components/team/PersonCard";
import { MEDIA_QUERIES } from "../config";
import { sortBy } from "lodash";
import toGreeklishSlug from "../utils/toGreeklishSlug";
import SEO from "../components/common/SEO";

function TeamPage({ data }) {
  const people = data.allContentfulPeople.nodes;
  const management = sortBy(
    people.filter(x => x.management),
    "order"
  );
  const others = sortBy(
    people.filter(x => !x.management),
    "order"
  );

  return (
    <Layout>
      <SEO title="Η ομάδα μας" />
      <main>
        <Section
          css={`
            text-align: center;

            ${H1} {
              margin-bottom: 80px;
              margin-top: 64px;
            }

            ${H3} {
              margin-bottom: 48px;
            }

            ${MEDIA_QUERIES.mobile} {
              ${H1} {
                margin-bottom: 32px;
                margin-top: 0;
              }
            }
          `}
        >
          <H1>Η ομάδα μας</H1>
          <H3>ΟΜΑΔΑ ΔΙΑΧΕΙΡΙΣΗΣ</H3>
        </Section>
        <Section
          css={`
            display: grid;
            grid-template-columns: repeat(auto-fit, 238px);
            gap: 23px;
            margin-bottom: 113px;

            ${MEDIA_QUERIES.mobile} {
              grid-template-columns: 1fr;
              justify-items: center;
            }
          `}
        >
          {management.map(person => (
            <Link key={person.name} to={toGreeklishSlug(person.name)}>
              <PersonCard person={person} />
            </Link>
          ))}
        </Section>
        <H3
          css={`
            margin-bottom: 48px;
            text-align: center;
          `}
        >
          ΚΑΘΗΓΗΤΕΣ (ΜΕ ΑΛΦΑΒΗΤΙΚΗ ΣΕΙΡΑ)
        </H3>
        <Section
          css={`
            display: grid;
            grid-template-columns: repeat(auto-fit, 238px);
            gap: 23px;
            margin-bottom: 172px;

            ${MEDIA_QUERIES.mobile} {
              grid-template-columns: 1fr;
              justify-items: center;
            }
          `}
        >
          {others.map(person => (
            <Link key={person.name} to={toGreeklishSlug(person.name)}>
              <PersonCard person={person} />
            </Link>
          ))}
        </Section>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query AllPeople {
    allContentfulPeople(sort: { fields: name, order: ASC }) {
      nodes {
        bio
        contentful_id
        name
        order
        management
        image {
          fixed(width: 476) {
            src
            width
          }
        }
      }
    }
  }
`;

export default TeamPage;
