import React, { useState } from "react";
import Img from "gatsby-image";
import styled from "styled-components";

const StyledImg = styled(Img)`
  max-width: 238px;
  height: 282px;
  margin-bottom: 24px;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 2px;
`;

const Bio = styled.div`
  font-size: 14px;
  line-height: 1.57;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function PersonCard({ person }) {
  const [over, setOver] = useState(false);

  return (
    <div
      onMouseEnter={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      css={`
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #000;
        max-width: 238px;
        padding-bottom: 24px;
        height: 100%;

        &:hover {
          background: var(--accent);
          padding: 24px;

          ${StyledImg} {
            max-width: 105px;
            height: 105px;
            object-fit: cover;
            object-position: center;
            border-radius: 100%;
            margin-bottom: 16px;
          }

          ${Name} {
            font-size: 20px;
            font-weight: 800;
            line-height: 1.2;
            margin-bottom: 16px;
          }

          ${Bio} {
            white-space: unset;
            overflow: unset;
            text-overflow: unset;
          }
        }
      `}
    >
      {person.image && <StyledImg fixed={person.image.fixed} />}
      <div>
        <Name>{person.name}</Name>
        <Bio>
          {over
            ? person.bio.split(",").map(tag => <div key={tag}>{tag}</div>)
            : person.bio}
        </Bio>
      </div>
    </div>
  );
}

export default PersonCard;
